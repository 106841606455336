export const HEADER = {
    // Navigation Menu
    currentOrder: 'navigation-currentOrder',
    products: 'navigation-products',
    shoppingList: 'navigation-shoppingList',
    recipes: 'navigation-recipes',
    search: 'navigation-search',
    login: 'navigation-login',
    logout: 'navigation-logout',
    profile: 'navigation-profile',
    accessibility: 'navigation-accessibility',
    // Navigation elements
    navSkip: 'nav-skip',
    navPanelHeaderLink: 'nav-panel-header-link',
    navLogo: 'nav-logo',
    menuDropdownButton: 'menu-dropdown-button',
    // Top-bar and USP's
    uspsList: 'usps-list',
    uspsTitle: 'usps-title',
    topBarLinks: 'top-bar-links',
    // Notifications
    notificationPriority: 'priority-notification',
    notificationPriorityClose: 'notification-priority-close',
    notificationToolTip: 'tooltip-notification',
    notificationTooltipClose: 'notification-tooltip-close',
    notificationDelivery: 'delivery-notification',
    // Search
    suggestion: 'suggestion',
    // Joining Forces Opt In
    jfForm: 'jf-form',
    jfAcceptButton: 'jf-accept-button',
    jfDeclineButton: 'jf-decline-button',
    jfSwitch: 'jf-switch',
};
export const FOOTER = {
    // Notices
    notices: 'notices',
};
